<!-- eslint-disable import/no-cycle -->
<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <rbac-add-tab
      :role-data="roleData"
      class="mt-2 pt-75"
    />
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import RbacAddTab from '@/components/roleManager/add/RbacAddTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    RbacAddTab,
  },
  setup() {
    const roleData = ref({})
    // store.dispatch('user/fetchUser', { id: router.currentRoute.params.id })
    //   .then(response => { userData.value = response.body })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined
    //     }
    //   })
    return {
      roleData,
    }
  },
}
</script>
