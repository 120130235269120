<template>
  <div>
    <validation-observer ref="updateUserform">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Role Name -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Role Name"
              label-for="roleName"
            >
              <validation-provider
                #default="{ errors }"
                name="Role Name"
                rules="required"
              >
                <b-form-input
                  id="roleName"
                  v-model="roleData.roleName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Permissions"
                rules="required"
              >
                <!-- PERMISSION TABLE -->
                <b-card
                  no-body
                  class="border mt-1"
                >
                  <b-card-header
                    v-model="permissionIds"
                    class="p-1"
                  >
                    <b-card-title class="font-medium-2">
                      <feather-icon
                        icon="LockIcon"
                        size="18"
                      />
                      <span class="align-middle ml-50">Permission</span>
                    </b-card-title>
                  </b-card-header>
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="moduleArray"
                  >
                    <template #cell(module)="data">
                      {{ convertModuleName(data.value) }}
                    </template>
                    <template #cell()="data">
                      <b-form-checkbox
                        :checked="data.value.value"
                        @change="onCheckModule(data)"
                      />
                    </template>
                  </b-table>
                </b-card>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="checkValidate"
    >
      Add
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      :to="{ name: 'role-manager' }"
      variant="outline-secondary"
    >
      Cancel
    </b-button>
    <b-button
      v-if="false"
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },

  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props) {
    const permissionIds = ref([])
    const dataFetchRoles = ref([])
    const toast = useToast()
    const moduleArray = ref([])
    const fetchPermissions = (ctx, callback) => {
      store
        .dispatch('roleManager/fetchPermission')
        .then(response => {
          dataFetchRoles.value = response.body
          dataFetchRoles.value.forEach(element => {
            const objectModule = {
              module: element.name,
              list: {
                value: false,
                status: false,
              },
              read: {
                value: false,
                status: false,
              },
              update: {
                value: false,
                status: false,
              },
              create: {
                value: false,
                status: false,
              },
              delete: {
                value: false,
                status: false,
              },
            }
            moduleArray.value.push(objectModule)
            dataFetchRoles.value.forEach(x => {
              x.permissions.forEach(y => {
                const objIndex = moduleArray.value.findIndex(
                  obj => obj.module === y.name.split(':')[0],
                )
                if (objIndex !== -1) {
                  moduleArray.value[objIndex][y.name.split(':')[1]].status = true
                }
              })
            })
          })
        })
        .catch(err => {
          console.log(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching permissions list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const onSubmit = () => {
      const arrNamePermissions = permissionIds.value.map(x => `${x.item.module}:${x.field.key}`)
      const payload = {
        name: props.roleData.roleName,
        permissions: arrNamePermissions,

      }
      store.dispatch('roleManager/addRole', payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Add permission success',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'role-manager' })
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    setTimeout(async () => {
      fetchPermissions()
    }, 0)
    return {
      permissionIds,
      router,
      moduleArray,
      onSubmit,
      dataFetchRoles,
    }
  },
  methods: {
    convertModuleName(data) {
      switch (data) {
        case 'post':
          return 'Local tips'
        case 'program':
          return 'Program'
        case 'postCategory':
          return 'Local tips category'
        case 'programCategory':
          return 'Program category'
        case 'roomService':
          return 'Room service'
        case 'customerFlow':
          return 'Customer'
        case 'general':
          return 'General config'
        case 'rating':
          return 'Rating config'
        case 'account':
          return 'User'
        case 'role':
          return 'Role'
        case 'tracking':
          return 'Tracking'
        default:
          return data
      }
    },
    onCheckModule(data) {
      const valueData = data.value
      this.moduleArray.find(e => e.module === data.item.module)[data.field.key].value = !valueData.value
      if (this.permissionIds.filter(e => e.item.module === data.item.module && e.field.key === data.field.key).length > 0) {
        this.permissionIds.splice(this.permissionIds.findIndex(e => e.item.module === data.item.module && e.field.key === data.field.key), 1)
      } else {
        this.permissionIds.push(data)
      }
    },
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
    checkValidate() {
      this.$refs.updateUserform.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
